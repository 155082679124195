import React from "react";
// routes
import Router from "./routes/sections";
// components
import ScrollToTop from "./components/scroll-to-top";
import { SplashScreen } from "./components/loading-screen";
// styles
import "swiper/css";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <React.Suspense fallback={<SplashScreen />}>
      <ScrollToTop />
      <Router />
    </React.Suspense>
  );
}
